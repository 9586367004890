import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Header from './components/header'
import Tasks from './components/tasks'
import AddTask from './components/addtask'
import Footer from './components/footer'
import About from './components/about'


function App() {
  const [showAddTask, setShowAddTask] = useState (false)

  const [tasks, setTasks] = useState ([])

  useEffect(() =>  {
    const getTasks = async () => {
      const tasksFromServer = await fetchTasks()
      setTasks(tasksFromServer)
    }
    getTasks()
  }, [])

  //fetch data
  const fetchTasks = async () => {
    const res = await fetch('http://localhost:5000/tasks')
    const data = await res.json()
    
    return data
  }

  //fetch data
  const fetchTask = async (id) => {
    const res = await fetch(`http://localhost:5000/tasks/${id}`)
    const data = await res.json()
    
    return data
  }

// add task
const addTask = async (task) => {
  const res = await fetch(`http://localhost:5000/tasks`, { 
    method: 'POST', 
    headers: { 
      'Content-type': 'application/json',
    }, 
    body: JSON.stringify(task),
  })

  const data = await res.json()

  setTasks([...tasks, data])
  // const id = Math.floor(Math.random() * 10000) +1
  // const newTask = { id, ...task }
  // setTasks([...tasks, newTask])
}

// delete task
const deleteTask = async (id) => {
  await fetch(`http://localhost:5000/tasks/${id}`, { method: 'DELETE'})
  setTasks(tasks.filter((task) => task.id != id))
}

// toggle reminder 
const toggleReminder = async (id) => {
  const taskToToggle = await fetchTask(id)
  const updTask = { ...taskToToggle,  reminder: !taskToToggle.reminder}

  const res = await fetch(`http://localhost:5000/tasks/${id}`, { 
    method: 'PUT', 
    headers: { 
      'Content-type': 'application/json',
    }, 
    body: JSON.stringify(updTask),
  })

  const data = await res.json()

  setTasks(
    tasks.map((task) => 
      task.id === id ? { ...task, reminder: 
        data.reminder } : task
      )
    )
}


  return (
      <Router>
        <div className="container">
          <Header onAdd={() => setShowAddTask(!showAddTask)} showAdd={showAddTask}/>
          
          <Route path='/' exact render={(props) => (
            <>
            {showAddTask && <AddTask onAdd={addTask}/>}
              {tasks.length > 0 ? (
                <Tasks tasks={tasks} onDelete={deleteTask} onToggle={toggleReminder}/>

              ) : (
                'No Tasks To Show'
              )}
            </>
          ) } />
          <Route path='/about' component={About} />
          <Footer />
        </div>
      </Router>
  );
}

export default App;
